import React from "react"
import {
  FaFacebookSquare
} from "react-icons/fa"

const Footer = () => {
  return <footer className="footer">
    <div> 
      <h4>
        copyright&copy;{new Date().getFullYear()}
        <span> ArlokTechnologies LLC</span> All rights reserved
      </h4>
    </div>
  </footer>
}

export default Footer
