import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"

const Error = () => {
  return (
  <Layout>
  <main className="error-page">
    <div className="error-container">
      <h1>Something went wrong! This page might not exist. Please return to the Home Page.</h1>
      <Link to="/" className="btn">
        Home Page
      </Link>
    </div>
  </main>
  </Layout>
  )
}

export default Error
